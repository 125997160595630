<template>
  <div class="social-space-chatrooms">
    <div class="d-flex chat-rooms">
      <ion-card
        class="chat-room-card text-white m-0"
        :style="[worldStatus.status !== 'member' && isAuthenticated && 'z-index: 1']"
      >
        <div
          class="cover"
          :style="`background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75)), url(${cover})`"
        />

        <div class="social-space-name mx-3 clickable-item-hov" @click="goToPage">
          {{ get(chatroom, 'world.name', '') }}
        </div>

        <ion-badge v-if="unReadCounts" color="danger" class="message-badge"
          >{{ unReadCounts }} New Messages in other chatrooms</ion-badge
        >
        <div class="d-flex">
          <div class="arrow-icon clickable-item-hov d-flex align-items-center" @click="goBack">
            <ion-icon :icon="arrowBack" />
          </div>
          <div
            class="chat-room-name-card d-flex align-items-center text-white px-2"
            :class="worldStatus.status !== 'member' ? 'py-2' : 'set-padding'"
          >
            <div class="img d-flex" :class="worldStatus.status !== 'member' ? 'mt-1' : ''">
              <img v-character-card-image :src="resizeUpload(chatroom.banner_img_url, '80x80')" />
            </div>

            <div class="mx-2">
              <div class="title d-flex align-items-center">
                <div>{{ truncateText(chatroom.title, 25) }}</div>
                <MatureTag v-if="isNsfw" class="ml-1" size="md" />
              </div>
              <div class="d-flex justify-content-between">
                <div class="mt-1">{{ count }} {{ count > 1 ? 'members' : 'member' }}</div>
              </div>
            </div>
            <div class="icon clickable-item-hov d-flex ml-auto" @click="openChatroomDetails">
              <ion-icon :icon="ellipsisVertical" />
            </div>
          </div>
        </div>
        <div
          v-if="worldStatus.status !== 'member' && tags?.length"
          class="chat-room-tags-card text-white px-3"
          :class="isMobSmallScreen ? 'py-2' : 'py-3'"
        >
          <div class="d-flex justify-content-center tag-container mb-1">
            <ion-badge
              v-for="(tag, index) of tags"
              :key="index"
              color="light"
              class="px-2 m-1 clickable-item-hov"
              @click="openTagSearch(tag)"
              ><span>#{{ tag }}</span></ion-badge
            >
          </div>
          <div v-if="chatroom.description" class="mx-4">
            <div class="sub-title">Description</div>
            <div class="description">{{ unescapeHTML(truncateText(stripHtmlTags(chatroom.description)), 120) }}</div>
          </div>
        </div>

        <div v-if="worldStatus.status !== 'member' && isAuthenticated" class="chat-room-join-card text-white px-3 py-2">
          <div class="sub-title px-2 mt-1">
            To join this chat you have to be a part of "{{ get(chatroom, 'world.name') }}"
          </div>
          <div class="d-flex mt-1" style="margin-bottom: 1px">
            <ion-button v-if="!isMobSmallScreen" class="px-2 social-btn w-100" color="primary" @click="goToSocialSpace"
              >Visit "{{ get(chatroom, 'world.name') }}"</ion-button
            >

            <ion-button
              v-if="get(loggedInUser, 'is_email_verified', true)"
              class="px-2 social-btn w-100"
              :disabled="isLoading"
              :class="{ hide: joinStatus.status === 'requested' || worldStatus.status === 'requested' }"
              @click="joinSocialSpaceButton"
            >
              <span v-if="joinStatus.status === 'requested' || worldStatus.status === 'requested'"
                >Pending Request</span
              >

              <div v-else>
                <ChLoading size="sm" v-if="isLoading" class="spinner" />
                <span v-else> Join "{{ get(chatroom, 'world.name') }}"</span>
              </div>
            </ion-button>
            <ion-button
              v-else
              class="px-2 social-btn w-100"
              :disabled="isLoading"
              :class="{ hide: joinStatus.status === 'requested' || worldStatus.status === 'requested' }"
              @click="openVerificationModal"
            >
              <span v-if="joinStatus.status === 'requested' || worldStatus.status === 'requested'"
                >Pending Request</span
              >

              <div v-else>
                <ChLoading size="sm" v-if="isLoading" class="spinner" />
                <span v-else> Join "{{ get(chatroom, 'world.name') }}"</span>
              </div>
            </ion-button>
          </div>
        </div>
      </ion-card>
      <join-social-space
        :is-open="isFormOpen"
        :modal-title="modalTitle"
        :name="socialspaceName"
        :is-chatroom="false"
        :socialspace-id="get(chatroom, 'world.id', '')"
        :chatroom-id="chatroom.id"
        @dismiss-modal="closeForm"
        @status="checkStatus"
      />

      <VerificationModal :is-open="verificationModal" @dismiss-modal="closeVerificationModal" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import MatureTag from '@/shared/components/MatureTag.vue';
import { ellipsisVertical, arrowBack } from 'ionicons/icons';
import JoinSocialSpace from '@/shared/modals/JoinSocialSpace.vue';
import { resizeUpload } from '@/shared/utils/upload';
import { stripHtmlTags } from '@/shared/utils/string';
import { createWorldCharUserRequest } from '@/shared/actions/worlds';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import VerificationModal from '@/shared/modals/VerificationModal.vue';
import { unescapeHTML } from '@/shared/utils/string';
import { truncateText } from '@/shared/utils/string';

const emits = defineEmits(['updated']);

const { user: loggedInUser, isAuthenticated } = authStore();

const router = useRouter();
const goToSocialSpace = () => {
  router.push({ name: 'social-space-details', params: { slug: chatroom.value.world.slug } });
};

const props = defineProps({
  chatroom: {
    type: Object,
    required: true,
  },
  chatroomStatus: {
    type: Object,
    required: true,
  },
  worldStatus: {
    type: Object,
    required: true,
  },
  count: {
    type: Number,
    default: 0,
  },
  unReadCounts: {
    type: Number,
    default: 0,
  },
});

const chatroom = toRef(props, 'chatroom');
const worldStatus = toRef(props, 'worldStatus') as any;
const isFormOpen = ref(false);
const joinStatus = ref({}) as any;
const isLoading = ref(false);

const verificationModal = ref(false);

const openVerificationModal = () => {
  verificationModal.value = true;
};

const closeVerificationModal = () => {
  verificationModal.value = false;
};

const ismember = ref(false);

const isNsfw = computed(() => chatroom.value?.world?.is_nsfw);

const closeForm = () => {
  isFormOpen.value = false;
};

const openTagSearch = (tagText: string) => {
  const hashText = '#';
  tagText = hashText.concat(tagText);
  router.push({ name: 'search', query: { term: tagText, tab: 'all' } });
};

const joinSocialSpaceButton = async () => {
  if (get(chatroom.value, 'world.privacy') === 'O') {
    isLoading.value = true;

    await createWorldCharUserRequest({
      requestee_id: loggedInUser.value.id,
      requestee_type: 'user',
      world: get(chatroom.value, 'world.id'),
      message: '',
    });
    emits('updated');
    isLoading.value = false;
    toast.show('You’re approved', 'nonative', 'success');
  } else {
    joinStatus.value.status === 'requested' || worldStatus.status === 'requested' ? '' : (isFormOpen.value = true);
  }
};

const cover = computed(() => {
  return get(chatroom.value, 'world.cover_image', '');
});

const checkStatus = (status: string) => {
  joinStatus.value = status;
};
const name = computed(() => {
  return 'my chatroom';
});
const modalTitle = computed(() => {
  return 'Join ' + get(chatroom.value, 'world.name', '');
});
const socialspaceName = computed(() => {
  return get(chatroom.value, 'world.name', '');
});

const goToPage = () => {
  router.push({ name: 'social-space-details', params: { slug: get(chatroom.value, 'world.slug') } });
};

const openChatroomDetails = () => {
  router.push({ name: 'chatroom-details', params: { id: chatroom.value.id } });
};
const username = computed(() => {
  return 'username';
});

const tags = computed(() => {
  return chatroom.value.tags;
});

const goBack = () => {
  router.go(-1);
};

const { width: windowWidth } = useWindowSize();

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 500;
});
</script>

<style lang="sass" scoped>
.set-padding
  padding-top: 6px
  padding-bottom: 6px
.hide
  pointer-events: none
.arrow-icon
  font-size: 24px
  justify-content: center
  display: flex
  padding-left: 0.75rem !important
  padding-right: 0.75rem !important
  width: 50px
.social-space-name
  margin-top: -35px
  position: absolute
  font-size: 20px
  font-weight: bold
  white-space: nowrap
  @media(max-width: 500px)
    font-size: 18px
.message-badge
  position: absolute
  right: 20px
  top: 15px
  border-radius: 10px
.cover
  height: 70px
  width: 100%
  background-position: center
  background-size: cover
  background-repeat: no-repeat
  background-blend-mode: lighten
  opacity: 0.5

.social-btn
  height: 43px
  text-transform: uppercase
  font-weight: 900
  white-space: pre-wrap
.button
  --overflow: unset !important
  --background: #00b4c5
  --border-radius: 10px
.social-space-chatrooms
  overflow: unset
  width: 100%

.sub-title
  font-size: 18px
  font-weight: bold
  @media(max-width:500px)
    font-size: 16px
.description
  font-size: 16px
  margin-top: 5px
.icon
  font-size: 24px
  font-weight: bold
  color: white
  // margin-top: -4px
.chat-room-card
  overflow: unset
  border-radius: 0
  background: #181c3b
  margin-inline: 0

  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important
.chat-room-join-card
  overflow: hidden
  border-radius: 0
  background: #40466b

  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important

.chat-room-name-card
  overflow: hidden !important
  border-radius: 0
  background: #40466b
  width: calc(100% - 50px)
  .title
    font-size: 18px
    font-weight: bold
    margin-top: -3px

.chat-room-tags-card
  overflow: unset
  border-radius: 0
  background: #626680
  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important

.img
  width: 50px !important
  height: 50px !important
  border-radius: 35px
  background: #4A9FC3
  overflow: hidden
  img
    object-fit: cover
    width: 100%
    height: 100%
  @media(max-width: 500px)
    width: 50px !important
    height: 50px !important
.chat-rooms
  overflow-x: auto
  overflow: unset

.chat-rooms::-webkit-scrollbar
  display: none
.chat-rooms
  -ms-overflow-style: none
  scrollbar-width: none
.tag-container
  display: flex
  flex-wrap: wrap
</style>
