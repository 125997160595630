<template>
  <div class="container h-100 flex-grow-1 d-flex flex-column bg-white">
    <div class="chatroom-page flex-grow-1 d-flex flex-column">
      <div id="scroll" style="scroll-margin-top: 200px"></div>

      <div ref="chatHeader" class="d-flex border-radius">
        <chatroom-header
          class="flex-grow-1"
          :chatroom="chatroom"
          :chatroom-status="chatroomstatus"
          :world-status="worldstatus"
          :count="chatroomMembersCount"
          :un-read-counts="chatroomUnReadCounts"
          @updated="fetchSocialSpaceStatus"
        />
      </div>

      <div
        ref="messagesContainer"
        :class="worldstatus.status === 'member' ? 'world-member-position' : 'world-nonmember-position'"
        class="messages-content flex-grow-1 px-2"
        @scroll="handleScroll"
      >
        <div v-if="isLoading" class="d-flex justify-content-center my-4">
          <ChLoading size="lg" class="spinner" />
        </div>
        <div v-if="chatMessages && chatMessages.length">
          <div v-for="(message, index) of formattedMessages" :key="message.id">
            <div :id="`message-${message.id}`" style="scroll-margin-bottom: 1rem">
              <chat-msg-item
                :message="message"
                :sender="sender"
                :receiver="receiver"
                :chatroom="chatMessages"
                :length="chatMessages.length - 1"
                :index="index"
                :current-message-user-char="currentMessageUserChar"
                @reply="reply"
              />
            </div>
          </div>
        </div>

        <div v-else-if="!chatMessages && !chatMessages.length && !isLoading" class="my-4">
          <div class="mb-2">No Messages Yet.</div>
          <div>Be the one to start the conversation!</div>
        </div>
      </div>

      <div ref="msgBox" class="msg-box mt-auto">
        <chatroom-text-box
          v-if="worldstatus.status === 'member'"
          :status="worldstatus"
          :chatroom="chatroom"
          :is-replying="isReplying"
          :replyid="replyId"
          :replyname="replyName"
          :replytext="replyText"
          :reply-img-url="replyImgUrl"
          @added="setImage"
          @remove="removeImage"
          @send="onMsgSent"
          @close="resetReply"
          @selected="selectedReplyName"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import chatroomHeader from './components/chatroomHeader.vue';
import chatroomTextBox from './components/chatroomTextBox.vue';
import chatMsgItem from './components/chatMsgItem.vue';
import {
  chatroomUnreadMessageCounts,
  getchatroomDetails,
  getChatroomMembers,
  getJoinedChatrooms,
  getSocialSpaceRoomMsgs,
} from '@/shared/actions/socialSpaceChatroom';
import { getMemberstatus, markWorldRoomMsgsRead } from '@/shared/actions/worlds';

import store from '@/shared/store';
import { authStore } from '@/shared/pinia-store/auth';

const { user, authToken } = authStore();
const chatroom: Ref = ref({});
const latestLoadedMessageId: any = ref(null);
const rooms = ref([]) as any;
const worldstatus = ref({ status: '', role: '' });
const chatroomstatus: Ref = ref({});
const chatroomMembersCount = ref(0);
const chatroomMembers = ref([]);
const chatMessages: any = ref([]);

const page = ref(1);
const router = useRouter();

const replyId = ref('');
const replyName = ref('');
const replyText = ref('');
const replyImgUrl = ref('');
const currentMessageUserChar = ref('');
const sender = ref([]);
const isLoading = ref(true);
const chatroomUnReadCounts = ref(0);
const receiver = ref([]);
const isReplying = ref(false);
const formattedMessages: any = ref([]);
const route = useRoute();
const image = ref([]);
const {
  public: { wsUrl },
} = useRuntimeConfig();
const { wsConnection, wsClose, wsConnect } = useSocket(
  `${wsUrl}/ws/worldchats/room/${route.params.id as string}/?token=${authToken.value}`
);
const pagingMsg = ref({
  next: null,
  previous: null,
  count: 0,
  page: 1,
}) as Ref<{ next: string | null; previous: string | null; count: number; page: number }>;
const msgBox: Ref = ref(null);
const chatHeader: Ref = ref(null);
const messagesContainer: Ref = ref(null);

const textBoxHeight = ref('0px');
const chatHeaderHeight = ref('0px');

const { height: windowHeight } = useWindowSize();

const fetchSocialSpaceStatus = async () => {
  const resp = await getMemberstatus({ world_id: get(chatroom.value, 'world.id', '') });
  worldstatus.value = resp;
  fetchjoinedChatrooms();
};

watch(chatroomstatus, () => {
  if (chatroom.value.privacy === 'private' && chatroomstatus.value.status !== 'member') {
    router.push({ name: 'chatroom-details', params: { id: chatroom.value.id } });
  }
});

const fetchSocialSpaceChatroomStatus = async () => {
  const resp = await getMemberstatus({
    world_id: get(chatroom.value, 'world.id', ''),
    room_id: get(chatroom.value, 'id', ''),
  });
  chatroomstatus.value = resp;
};

const fetchjoinedChatrooms = async () => {
  if (worldstatus.value.status === 'member') {
    const resp = await getJoinedChatrooms(get(chatroom.value, 'world.id'), 1);
    rooms.value = resp.results;
  }
};

const getRoomDetail = async () => {
  const resp = await getchatroomDetails(route.params.id as string);
  chatroom.value = resp;
  await Promise.all([fetchSocialSpaceStatus(), fetchSocialSpaceChatroomStatus()]);
  fetchMessages(false);
  hookSocialchatRoomWS();
};

const fetchChatroomMembers = async () => {
  const { results, ...paging } = await getChatroomMembers(route.params.id as string);
  chatroomMembers.value = results;
  chatroomMembersCount.value = paging.count;
};

onMounted(async () => {
  const document = useDocument();
  // document.value?.getElementById('scroll')?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
  store.dispatch('CharactersModule/getUserCharacters', {
    id: user.value.id,
    includeUnlisted: chatroom.value?.privacy === 'private' || ['M', 'U'].includes(chatroom.value?.world?.privacy),
  });
  getRoomDetail();
  fetchChatroomMembers();
  fetchChatroomUnreadMessageCounts();
});

const fetchChatroomUnreadMessageCounts = async () => {
  const resp = await chatroomUnreadMessageCounts(route.params.id as string);
  chatroomUnReadCounts.value = resp.unread_count;
};

const setImage = (newImage: any) => {
  image.value = image.value.concat(newImage);
};
const removeImage = (newImage: any) => {
  image.value = newImage;
};

const isChanging = computed(() => {
  return `${isLoading.value}-${isReplying.value}-${replyText.value}-${replyImgUrl.value}-${!isEmpty(image.value)}`;
});

watch(isChanging, () => {
  setTimeout(() => {
    textBoxHeight.value = msgBox.value ? `${msgBox.value.offsetHeight || 0}px` : '0px';
    chatHeaderHeight.value = chatHeader.value ? `${chatHeader.value.offsetHeight || 0}px` : '0px';
  }, 30);
});

const hookSocialchatRoomWS = () => {
  wsConnect();

  wsConnection.value.onmessage = (event) => {
    const data = JSON.parse(event.data).message;

    if (data.action === 'create') {
      chatMessages.value.push(data.message);
      nextTick(() => {
        document
          .getElementById(`message-${get(data.message, 'id')}`)
          ?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
      });
    }
    receiverMessages();
    senderMessages();
    fetchFormattedChatroomMessages();
  };
};

const reply = (id: any, name: string, text: string, imgUrl: any) => {
  isReplying.value = true;
  replyId.value = id;
  replyName.value = name;
  replyText.value = text;
  replyImgUrl.value = imgUrl;
};
const resetReply = () => {
  isReplying.value = false;
  replyId.value = '';
  replyName.value = '';
  replyText.value = '';
  replyImgUrl.value = '';
};

const onMsgSent = (value: any) => {
  isReplying.value = false;
  image.value = [];
};

const selectedReplyName = (value: any) => {
  currentMessageUserChar.value = value;
};

const fetchFormattedChatroomMessages = () => {
  formattedMessages.value = chatMessages.value.map((message: any, index: any) => {
    const previousMessage = chatMessages.value[index - 1];
    const nextMessage = chatMessages.value[index + 1];

    const isSenderAfterReceiver = previousMessage && previousMessage.user.username !== message.user.username;
    const isReceiverAfterSender = nextMessage && nextMessage.user.username !== message.user.username;
    return {
      ...message,
      isSenderAfterReceiver,
      isReceiverAfterSender,
      //   isNewCharacter: isCharacter,
    };
  });
};
const receiverMessages = () => {
  receiver.value = chatMessages.value
    .filter((item: any) => item.user.username !== user.value.username)
    .map((item: any) => item);
};

const senderMessages = () => {
  sender.value = chatMessages.value
    .filter((item: any) => item.user.username === user.value.username)
    .map((item: any) => item);
};

const getUnreadWorldsMsgs = async () => {
  await store.dispatch('WorldsModule/getUnreadWorldsMsgs');
};

const fetchMessages = async (value: boolean, pageNo = 1, pageSize = 50) => {
  if (!value) isLoading.value = true;

  let latestMsg: {} | undefined;
  try {
    const { results, ...paging } = await getSocialSpaceRoomMsgs(route.params.id as string, pageNo, pageSize);

    const reverseResults = orderBy(results, ['created'], ['asc']);

    latestMsg = last(reverseResults);
    latestLoadedMessageId.value = get(latestMsg, 'id');

    chatMessages.value = [...reverseResults, ...chatMessages.value];
    pagingMsg.value = paging;
    page.value = pageNo;

    if (page.value === 1 && latestMsg) {
      setTimeout(() => {
        document
          .getElementById(`message-${get(latestMsg, 'id')}`)
          ?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }, 600);

      await markWorldRoomMsgsRead(chatroom.value.id || (route.params.id as string));
      getUnreadWorldsMsgs();
    }
    receiverMessages();
    senderMessages();
    fetchFormattedChatroomMessages();
    nextTick(() => {
      const latestLoadedMessageElement = document.getElementById(`message-${latestLoadedMessageId.value}`);

      if (!messagesContainer.value || !latestLoadedMessageElement) return;

      if (latestLoadedMessageElement) {
        messagesContainer.value.scrollTop = latestLoadedMessageElement.offsetTop - 100;
      }
    });
  } catch (_err) {
    // TODO: Handle errors
  } finally {
    isLoading.value = false;
  }
};

const requestLoadMore = async () => {
  if (!pagingMsg.value.next) {
    return;
  }
  await fetchMessages(false, page.value + 1);
};
const handleScroll = () => {
  const scrollElement = messagesContainer.value;

  if (scrollElement) {
    const isAtTop = scrollElement.scrollTop === 0;
    if (isAtTop) {
      requestLoadMore();
    }
  }
};
</script>

<style scoped lang="sass">
.world-nonmember-position
  bottom: 0 !important
  top: 284px !important
.border-radius
  border-radius: 20px 20px 0 0
.container
  border-radius: 20px 20px 0 0
  min-height: 100%
  z-index: 110
  overflow: unset !important
.chatroom-page
  @media(min-width:570px)
    max-height: calc(100dvh - var(--default-nav-bar-height)) !important
.ios .chatroom-page
  @media(min-width:570px)
    max-height: calc(100dvh - 126px) !important
// .ios .container
  // margin-top: -64px !important
.spinner
  width: 50px
  height: 50px
.messages-content
  overflow: auto
  background: #f3f3f3
  // margin-bottom: -18px
  padding-top: 0.5rem
  padding-bottom: 1rem

  @media (max-width: 570px)
    bottom: 70px
    top: 133px
    width: 100%

.ios .world-member-position
  @media (max-width: 570px)
    bottom: 90px !important

.dark .messages-content
  background: #010123 !important
</style>
